aside{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 170px;
  left: -120px;
  background-color: $white;
  z-index: 3;
  transition: all .5s;
  border-right: 1px solid $black;
  //min-width: 200px;
  &.open{
    left: 0;
    transition: all .5s;
    .toggler{
      span{
        &:after{
          content: attr(data-close);
        }
      }
    }
  }
  ul{
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding-right: 50px;
    padding-left: 20px;
    li{
      list-style: none;
      &.current-menu-item{
        a{
          //font-style: italic;
          text-decoration: none;
        }
      }
      a{
        text-decoration: underline;
        font-size: 18px;
        //text-transform: lowercase;
        color: $black;
      }
    }
  }
  .menu-social-menu-container{
    position: absolute;
    bottom: 50px;
    ul{
      padding-right: 50px;
    }
  }
}
.toggler{
  height: 50px;
  width: 100%;
  padding-left: calc(100% - 50px);
  text-align: center;
  &:hover{
    span{
      animation-name: pulse_animation;
      animation-duration: 5000ms;
      transform-origin:70% 70%;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  span{
    font-size: 36px;
    width: 50px;
    height: 50px;
    display: inline-block;
    &:after{
      font-size: 36px;
      width: 50px;
      height: 50px;
      content: attr(data-open);
      display: block;
      line-height: 50px;
    }
    cursor: pointer;
  }
}
.post-nav{
  position: absolute;
  width: 50px;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  a{
    @include ms-respond(font-size, 1.8);
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    padding: 10px;
    line-height: 30px;
  }
  .counter{
    @include ms-respond(font-size, 1);
    width: 50px;
    padding: 0 10px;
    display: inline-block;
    text-align: center;
  }
}

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}