html{
	.page{
		form{
			&>p{
				width: 100%;
				max-width: 300px;
			}
		}

		input{
			height: 40px;
			width: 100%;
			padding: 5px;
			display: inline-block;
			border: 1px solid $black;
		}

		textarea{
			resize: none;
			height: 250px;
			width: 100%;
			padding: 5px;
			display: inline-block;
			border: 1px solid $black;
			border-radius: 0;
			height: 250px;
		}

		.ui-input-btn{
			border-radius: 0;
			border: 1px solid $black;
			height: 50px;
			line-height: 50px;
			transition: all .3s;
			input{
				display: none;
			}
			&:hover{
				background-color: $black;
				color: $white;
				transition: all .3s;
			}
		}
	}
}