.flex{
  max-width: 100%;
  display: flex;
  img{
    width: auto;
    display: block;
    max-width: 100%;
  }
  .teaser{
    max-width: 50%;
    margin-left: 1em;
  }
}