.breadcrumb{
  height: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  padding-left: 20px;
  overflow: hidden;
  //pointer-events: all;
  @include ms-respond(font-size, .7);
  @media (max-width: $sm) {
    width: 100%;
    position: static;
    border-bottom: 1px solid $black;
  }
  a{
    //text-transform: lowercase;
    color: $black;
    //padding: 0 10px;
    &.current-page{
      //font-style: italic;
      text-decoration: none;
    }
  }
}